<script setup lang="ts">
const { step, setStep } = useCheckoutStep()

watch(
  () => useRoute().name,
  (name) => {
    if (name === 'checkout')
      return setStep(0)
    if (name === 'checkout-entrar')
      return setStep(1)
    if (name === 'checkout-dados-pessoais')
      return setStep(2)
    if (name === 'checkout-agendamento')
      return setStep(3)
    if (name === 'checkout-endereco-de-cobranca')
      return setStep(4)
    if (name === 'checkout-endereco-de-cobranca')
      return setStep(4)
    if (name === 'checkout-pedido-medico')
      return setStep(5)
    if (name === 'checkout-forma-de-pagamento')
      return setStep(6)
    if (name === 'checkout-pedido-realizado')
      return setStep(7)

    return setStep(8)
  },
  { immediate: true },
)
</script>

<template>
  <div v-if="step.showTitle" class="absolute inset-x-0">
    <div class="flex items-center xl:justify-between container mx-auto w-full pt-5">
      <div class="xl:flex-1 xl:mx-0 mx-8">
        <BackButton hidden-text-when-mobile />
      </div>

      <div class="flex-1 gap-3 xl:flex xl:w-full">
        <div class="flex xl:justify-center items-center xl:w-full">
          <span class="text-2xl xl:text-center xl:whitespace-nowrap xl:text-3xl text-primary-400 xl:w-full">
            {{ step.title }}
          </span>
        </div>
      </div>

      <div class="hidden xl:block flex-1" />
    </div>

    <ProgressBar v-if="step.progress" :current="step.percent" />
  </div>
</template>
